<template>
  <div id="translation-list">
    <div class="vx-card p-6">
      <vs-table ref="table" class="w-100" :data="translations" max-items="15" pagination>
        <template slot="thead">
          <vs-th sort-key="_domain" class="w-1/5">
            <span class="mx-auto">{{ $t("translation.table.header.domain") }}</span>
          </vs-th>
          <vs-th sort-key="_key" class="w-1/5">
            <span class="mx-auto">{{ $t("translation.table.header.key") }}</span>
          </vs-th>
          <vs-th sort-key="fr" class="w-1/5">
            <span class="mx-auto flex">
              {{ $t("translation.table.header.french") }}
              <img class="w-5 h-5 ml-2" :src="require(`@/assets/images/flags/fr.png`)" />
            </span>
          </vs-th>
          <vs-th sort-key="en" class="w-1/5">
            <span class="mx-auto flex">
              {{ $t("translation.table.header.english") }}
              <img class="w-5 h-5 ml-2" :src="require(`@/assets/images/flags/en.png`)" />
            </span>
          </vs-th>
          <vs-th sort-key="de" class="w-1/5">
            <span class="mx-auto flex">
              {{ $t("translation.table.header.german") }}
              <img class="w-5 h-5 ml-2" :src="require(`@/assets/images/flags/de.png`)" />
            </span>
          </vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr class="search-row">
            <vs-td>
              <vs-select v-model="payload._domain"
                         @input="searchInLexikTranslations(payload)"
                         class="my-5 mx-auto"
              >
                <vs-select-item :text="$t('translations.table.select.all')" value=""/>
                <vs-select-item :key="index"
                                :value="index"
                                :text="$t(`pricing.sheet.type.${name}`)"
                                v-for="(name, index) in sortedDomains"
                />
              </vs-select>
            </vs-td>
            <vs-td>
              <vs-input v-model="payload._key"
                        @input="searchInLexikTranslations(payload)"
                        class="my-5 mx-auto"
                        icon="search"
                        icon-after="true"
              />
            </vs-td>
            <vs-td>
              <vs-input v-model="payload.fr"
                        @input="searchInLexikTranslations(payload)"
                        class="my-5 mx-auto"
                        icon="search"
                        icon-after="true"
              />
            </vs-td>
            <vs-td>
              <vs-input v-model="payload.en"
                        @input="searchInLexikTranslations(payload)"
                        class="my-5 mx-auto"
                        icon="search"
                        icon-after="true"
              />
            </vs-td>
            <vs-td>
              <vs-input v-model="payload.de"
                        @input="searchInLexikTranslations(payload)"
                        class="my-5 mx-auto"
                        icon="search"
                        icon-after="true"
              />
            </vs-td>
          </vs-tr>
          <vs-tr v-for="(tr, indextr) in data" :key="indextr" class="table-space" >
            <vs-td :data="data[indextr]._domain">
              {{ $t(`pricing.sheet.type.${domainTranslationKeys[data[indextr]._domain]}`) }}
            </vs-td>

            <vs-td :data="data[indextr]._key">
              {{data[indextr]._key}}
            </vs-td>

            <vs-td  class="mx-auto" :data="data[indextr].fr">
              <vs-textarea v-model="data[indextr].fr"
                           @change="updateLexikTranslations(data[indextr], data[indextr].fr)">
                {{data[indextr].fr}}
              </vs-textarea>
            </vs-td>

            <vs-td :data="data[indextr].en">
              <vs-textarea v-model="data[indextr].en"
                           @change="updateLexikTranslations(data[indextr], data[indextr].fr)">
                {{data[indextr].en}}
              </vs-textarea>
            </vs-td>

            <vs-td :data="data[indextr].de">
              <vs-textarea v-model="data[indextr].de"
                           @change="updateLexikTranslations(data[indextr], data[indextr].fr)">
                {{data[indextr].de}}
              </vs-textarea>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>
  </div>
</template>

<script>
import moduleTranslationMixin from '@/store/translation/moduleTranslationMixin'

const cloneDeep = require('lodash.clonedeep')

export default {
  mixins: [moduleTranslationMixin],
  data () {
    return {
      translations: [],
      domainTranslationKeys: {},
      payload: {
        _domain: '',
        _key: '',
        fr: '',
        en: '',
        de: ''
      }
    }
  },
  computed: {
    sortedDomains () {
      const obj = this.domainTranslationKeys
      return Object.fromEntries(Object.entries(obj).sort((a, b) => a[0].localeCompare(b[0])))
    }
  },
  methods: {
    async fetchLexikTranslations () {
      const lexikTranslations = await this.$store.dispatch('translation/fetchLexikTranslations')
      this.translations = cloneDeep(lexikTranslations)
    },
    updateLexikTranslations (content) {
      this.$store.dispatch('translation/updateLexikTranslations', content)
    },
    async searchInLexikTranslations (payload) {
      this.translations = await this.$store.dispatch('translation/searchInLexikTranslations', payload)
      if (this.$refs.table.currentx > 1) {
        this.$refs.table.currentx = 1
      }
    },
    async fetchSheetTypes () {
      await this.$store.dispatch('translation/fetchSheetTypes')
      this.domainTranslationKeys = this.$store.state.translation.domainTranslationKeys
    }
  },
  async created () {
    await this.fetchSheetTypes()
    await this.fetchLexikTranslations()
  }
}
</script>

<style lang="scss">
.table-space {
  overflow-wrap: anywhere;
}
.vs-textarea {
  min-height: 10rem;
}
.vs-input--icon {
  top: 11px;
}
.vs-con-input-label,
.con-select {
  width: auto;
}
.search-row {
  background: #f8f8f8;
}
</style>
