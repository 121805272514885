import moduleTranslation from './moduleTranslation'

export default {
  created () {
    if (!moduleTranslation.isRegistered) {
      this.$store.registerModule('translation', moduleTranslation)
      moduleTranslation.isRegistered = true
    }
  }
}
