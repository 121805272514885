import axios from '@/axios'
import qs from 'qs'

function filterTranslationDomains (response, whiteListedDomains) {
  return response.filter((el) => whiteListedDomains.includes(el._domain))
}

export default {
  isRegistered: false,
  namespaced: true,
  state: {
    translations: null,
    whiteListedDomains: [],
    domainTranslationKeys: {}
  },
  mutations: {
    SET_TRANSLATIONS (state, translations) {
      state.translations = translations
    },
    SET_WHITE_LISTED_DOMAINS (state, domains) {
      state.whiteListedDomains = domains
    },
    SET_DOMAIN_TRANSLATION_KEYS (state, domainTranslationKeys) {
      state.domainTranslationKeys = domainTranslationKeys
    }
  },
  actions: {
    async fetchSheetTypes ({ commit }) {
      try {
        const sheetTypes = await axios.get('sheet_types')
        const whiteListedDomains = sheetTypes.data['hydra:member'].map(el => el.currentTranslationDomain)

        const domainTranslationKeys = {}
        sheetTypes.data['hydra:member'].forEach(function (sheetType) {
          domainTranslationKeys[sheetType.currentTranslationDomain] = sheetType.name
        })

        commit('SET_WHITE_LISTED_DOMAINS', whiteListedDomains)
        commit('SET_DOMAIN_TRANSLATION_KEYS', domainTranslationKeys)
      } catch (error) {
        console.error(error) // eslint-disable-line no-console
      }
    },
    async fetchLexikTranslations ({ commit, state }) {
      try {
        const { data } = await axios.get('/trans/api/translations?_domain=&_search=true&page=1&rows=9999')
        const lexikAllDatas = data.translations
        const lexikFilterDatas = filterTranslationDomains(lexikAllDatas, state.whiteListedDomains)
        commit('SET_TRANSLATIONS', lexikFilterDatas)
        return lexikFilterDatas
      } catch (error) {
        console.error(error) // eslint-disable-line no-console
      }
    },
    updateLexikTranslations (_, content) {
      return new Promise((resolve, reject) => {
        const url = `/trans/api/translations/${content._id}`
        const data = qs.stringify(content)
        axios
          .put(url, data)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    async searchInLexikTranslations ({ state }, payload) {
      try {
        const { data } = await axios.get(`/trans/api/translations?_domain=${payload._domain}&_key=${payload._key}&fr=${payload.fr}&en=${payload.en}&de=${payload.de}&_search=true&page=1&rows=9999`)
        const searchAllResults = data.translations
        return filterTranslationDomains(searchAllResults, state.whiteListedDomains)
      } catch (error) {
        console.error(error) // eslint-disable-line no-console
      }
    }
  },
  getters: {
  }
}
